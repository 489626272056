"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselHandler = void 0;
var Carousel_1 = require("./Carousel");
var CarouselHandler = /** @class */ (function () {
    function CarouselHandler(carousel) {
        this.carousel = carousel;
        this.swiper = this.carousel.swiper;
    }
    /**
     * @method init Swiperの初期化
     * @param {HTMLElement} container コンテナ要素
     **/
    CarouselHandler.prototype.init = function () {
        var _a, _b;
        if (!this.carousel.container)
            return;
        if (this.carousel.type === "with-thumbs") {
            if (!this.carousel.thumbs)
                return;
            var thumbs = new Carousel_1.Carousel(this.carousel.thumbs);
            thumbs.constructSwiper();
            (_a = thumbs.swiper) === null || _a === void 0 ? void 0 : _a.init();
            this.carousel.option = {
                thumbs: {
                    swiper: this.thumbsSwiper,
                    slideThumbActiveClass: "p-carousel--thumb--active",
                    thumbsContainerClass: "p-carousel--thumbs",
                },
            };
        }
        (_b = this.swiper) === null || _b === void 0 ? void 0 : _b.init();
    };
    /**
     * @method loopFirstView スライドが1以上の時にloop
     * @param {Swiper} swiper
     */
    CarouselHandler.prototype.loopFirstView = function (swiper) {
        var slides = swiper.slides.length;
        // if (slides > 1) return
        swiper.loopCreate();
    };
    return CarouselHandler;
}());
exports.CarouselHandler = CarouselHandler;
