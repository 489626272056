"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarouselListener = void 0;
var CarouselHandler_1 = require("./CarouselHandler");
var CarouselListener = /** @class */ (function (_super) {
    __extends(CarouselListener, _super);
    function CarouselListener(element) {
        return _super.call(this, element) || this;
    }
    /**
     * @method listenToFirstView 初夏後にloopのハンドラを実行
     * @param {Swiper} swiper
     */
    CarouselListener.prototype.listenToFirstView = function (swiper) {
        var _this = this;
        swiper.on("afterInit", function (swiper) {
            console.log("hoge", swiper.slides.length);
            _this.loopFirstView(swiper);
        });
    };
    return CarouselListener;
}(CarouselHandler_1.CarouselHandler));
exports.CarouselListener = CarouselListener;
