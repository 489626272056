"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS_CLASS_NAMES = exports.PREFIX = exports.CLASS_NAMES = void 0;
exports.CLASS_NAMES = {
    ACTIVE: "is-active",
};
exports.PREFIX = {
    CLASS_NAME: ".",
    TAG_NAME: "#",
};
/**
 * スクリプト用 汎用クラス名
 */
exports.STATUS_CLASS_NAMES = {
    ACTIVE: "is-active",
    OPEN: "is-open",
    SHOWN: "is-shown",
    HIDDEN: "is-hidden",
    SCROLLABLE: "is-scrollable",
    FIRST: "is-first",
    LAST: "is-last",
    SCROLLLOCK: "is-scrollLock",
};
