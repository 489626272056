"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var CAROUSEL = __importStar(require("./constants/caoruselConstants"));
var Carousel_1 = require("./modules/Carousel");
var CarouselListener_1 = require("./modules/CarouselListener");
var carouselList = document.getElementsByClassName(CAROUSEL.CLASS_NAMES.CONTAINER);
function carousel() {
    if (carouselList) {
        for (var j = 0; j < carouselList.length; j++) {
            var element = carouselList[j];
            var carousel_1 = new Carousel_1.Carousel(element);
            carousel_1.constructSwiper();
            var listener = new CarouselListener_1.CarouselListener(carousel_1);
            if (carousel_1.type === CAROUSEL.CAROUSEL_DATA_ATTRIBUTES.FIRSTVIEW_TYPE && listener.swiper) {
                listener.listenToFirstView(listener.swiper);
            }
            listener.init();
        }
    }
}
document.addEventListener("DOMContentLoaded", carousel, { once: true });
