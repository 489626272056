"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAROUSEL_DATA_ATTRIBUTES = exports.CLASS_NAMES = void 0;
exports.CLASS_NAMES = {
    CONTAINER: "js-carousel",
    WRAPPER: "js-carouselWrapper",
    SLIDE: "js-carouselSlide",
    PAGINATION: "js-carouselPagination",
    NEXT: "js-carouselNav--next",
    PREV: "js-carouselNav--prev",
    SCROLLBAR: "js-carouselScrollbar",
};
exports.CAROUSEL_DATA_ATTRIBUTES = {
    FIRSTVIEW_TYPE: "firstview",
    WITH_THUMBS_TYPE: "with_thumbs",
    THUMBS_TYPE: "thumbs",
};
